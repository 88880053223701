import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import config from '../../config';

import css from './ListingPage.module.css';

const SectionHeading = props => {
  const {
    priceTitle,
    formattedPrice,
    richTitle,
    publicData,
    hostLink,
    showContactUser,
    lmctUnverified,
    onContactUser,
    isAuthenticated,
  } = props;
  if (!publicData) {
    return null;
  }

  const unitType = config.bookingUnitType;

  return (
    <div className={css.sectionHeading}>
      {isAuthenticated && !lmctUnverified && priceTitle ? (
        <div className={css.desktopPriceContainer}>
          <div className={css.desktopPriceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.desktopPerUnit}>
            <FormattedMessage id="ListingPage.priceGuide" />
          </div>
        </div>
      ) : null}
      <div className={css.heading}>
        <h1 className={css.title}>
          {publicData.modelYear ? (
            <span>{`${publicData.modelYear} `}</span>
          ) : null}
          {richTitle}
        </h1>
        <div className={css.author}>
          {/* <FormattedMessage id="ListingPage.hostedBy" values={{ name: hostLink }} /> */}
          {/* {showContactUser ? (
            <span className={css.contactWrapper}>
              <span className={css.separator}>•</span>
              <InlineTextButton
                rootClassName={css.contactLink}
                onClick={onContactUser}
                enforcePagePreloadFor="SignupPage"
              >
                <FormattedMessage id="ListingPage.contactUser" />
              </InlineTextButton>
            </span>
          ) : null} */}
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;

import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage } from '../../components';

import css from './ListingPage.module.css';


const ResponsiveImageMain = ({ image, title }) => {
  return (
    <div>
      <ResponsiveImage
        rootClassName={css.rootForImage}
        alt={title}
        image={image}
        variants={['landscape-crop', 'landscape-crop2x', 'landscape-crop4x', 'landscape-crop6x']}
      />
    </div>
  );
};

const SectionConditionsMaybe = props => {
  const { title, listing, publicData } = props;

  const frontWheelIds = publicData && publicData.frontWheelIds ? publicData.frontWheelIds : null;
  const rearWheelIds = publicData && publicData.rearWheelIds ? publicData.rearWheelIds : null;
  const panelWorkIds = publicData && publicData.panelWorkIds ? publicData.panelWorkIds : null;
  const painWorkIds = publicData && publicData.painWorkIds ? publicData.painWorkIds : null;
  const interiorIds = publicData && publicData.interiorIds ? publicData.interiorIds : null;
  const windscreenIds = publicData && publicData.windscreenIds ? publicData.windscreenIds : null;
  const mechanicalIds = publicData && publicData.mechanicalIds ? publicData.mechanicalIds : null;
  const dashLightImageIds =
    publicData && publicData.dashLightImageIds ? publicData.dashLightImageIds : null;

  const getImageArr = (images = [], type = '', ids = []) => {
    let imgArr = [];
    if (images && Array.isArray(images) && images.length > 0) {
      imgArr = images.filter(imgObj => {
        if (imgObj && imgObj.id) {
          if (typeof imgObj.id === 'string' && imgObj.id.split('_')[2] === type) {
            return true;
          } else if (
            typeof imgObj.id === 'object' &&
            imgObj.id.uuid &&
            ids &&
            ids.length > 0 &&
            ids.includes(imgObj.id.uuid)
          ) {
            return true;
          }
        }
        return false;
      });
    }
    return imgArr;
  };

  const images = listing.images;
  const frontWheelImage = getImageArr(images, 'frontWheelImage', frontWheelIds);
  const rearWheelImage = getImageArr(images, 'rearWheelImage', rearWheelIds);
  const panelWorkImage = getImageArr(images, 'panelWorkImage', panelWorkIds);
  const paintWorkImage = getImageArr(images, 'paintWorkImage', painWorkIds);
  const interiorImage = getImageArr(images, 'interiorImage', interiorIds);
  const windscreenImage = getImageArr(images, 'windscreenImage', windscreenIds);
  const mechanicalImage = getImageArr(images, 'mechanicalImage', mechanicalIds);
  const dashLightImage = getImageArr(images, 'dashLightImageIds', dashLightImageIds);

  const conditionClasses = classNames(css.point_detail, css.conditionImagesList);

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.conditionsTitle" />
      </h2>
      <section>
        <ul className={conditionClasses}>
          <li>
            <span className={css.form_label}>Front Wheels</span>
            <div className={css.imageListWrapper}>
              {frontWheelImage && frontWheelImage.length > 0
                ? frontWheelImage.map((img, index) => {
                    return <ResponsiveImageMain key={index} image={img} title={title} />;
                  })
                : null}
            </div>
          </li>
          <li>
            <span className={css.form_label}>Rear Wheels</span>
            <div className={css.imageListWrapper}>
              {rearWheelImage && rearWheelImage.length > 0
                ? rearWheelImage.map((img, index) => {
                    return <ResponsiveImageMain key={index} image={img} title={title} />;
                  })
                : null}
            </div>
          </li>
          <li>
            <span className={css.form_label}>Paint and Panel work</span>
            <div className={css.imageListWrapper}>
              {panelWorkImage && panelWorkImage.length > 0
                ? panelWorkImage.map((img, index) => {
                    return <ResponsiveImageMain key={index} image={img} title={title} />;
                  })
                : null}
            </div>
          </li>
          <li>
            <span className={css.form_label}>Instrument cluster</span>
            <div className={css.imageListWrapper}>
              {paintWorkImage && paintWorkImage.length > 0
                ? paintWorkImage.map((img, index) => {
                    return <ResponsiveImageMain key={index} image={img} title={title} />;
                  })
                : null}
            </div>
          </li>
          <li>
            <span className={css.form_label}>Interior</span>
            <div className={css.imageListWrapper}>
              {interiorImage && interiorImage.length > 0
                ? interiorImage.map((img, index) => {
                    return <ResponsiveImageMain key={index} image={img} title={title} />;
                  })
                : null}
            </div>
          </li>
          <li>
            <span className={css.form_label}>Windscreen</span>
            <div className={css.imageListWrapper}>
              {windscreenImage && windscreenImage.length > 0
                ? windscreenImage.map((img, index) => {
                    return <ResponsiveImageMain key={index} image={img} title={title} />;
                  })
                : null}
            </div>
          </li>
          <li>
            <span className={css.form_label}>Mechanical</span>
            <div className={css.imageListWrapper}>
              {mechanicalImage && mechanicalImage.length > 0
                ? mechanicalImage.map((img, index) => {
                    return <ResponsiveImageMain key={index} image={img} title={title} />;
                  })
                : null}
            </div>
          </li>

          {dashLightImage && dashLightImage.length > 0 ? (
            <>
              <li>
                <span className={css.form_label}>Dash Lights</span>
                <div className={css.imageListWrapper}>
                  {dashLightImage.map((img, index) => {
                    return <ResponsiveImageMain key={index} image={img} title={title} />;
                  })}
                </div>
              </li>
            </>
          ) : null}
        </ul>
      </section>
    </div>
  );
};

export default SectionConditionsMaybe;

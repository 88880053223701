import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionFeaturesMaybe = props => {
  const {
    publicData,
    transmissionOptions,
    bodyTypeOptions,
    makeOptions,
    modelOptions,
    fuelTypeOptions,
    drivingWheelOptions,
    interiorTypeOptions,
  } = props;
  if (!publicData) {
    return null;
  }

  const getSelectedOption = (selectedOption = '', options = '') => {
    let ret = 'Not Provided';
    if (selectedOption && options && options.length > 0) {
      ret = options.find(x => x.key === selectedOption);
      ret = ret ? ret.label : 'Not Provided';
    }
    return ret;
  };

  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.featuresTitle" />
      </h2>
      <section>
        <ul className={css.point_detail}>
          <li>
            <span className={css.form_label}>Registration</span>
            <span className={css.show_label}>
              {publicData.registration ? publicData.registration : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Registration Expiry</span>
            <span className={css.show_label}>
              {publicData.registrationExpiry ? publicData.registrationExpiry : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Vin Number</span>
            <span className={css.show_label}>
              {publicData.vin ? publicData.vin : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Build</span>
            <span className={css.show_label}>
              {publicData.buildDate
                ? new Date(publicData.buildDate).toLocaleDateString('en-AU', {
                    year: 'numeric',
                    month: 'long',
                  })
                : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Make</span>
            <span className={css.show_label}>
              {publicData.make ? getSelectedOption(publicData.make, makeOptions) : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Model</span>
            <span className={css.show_label}>
              {publicData.carModel
                ? getSelectedOption(publicData.carModel, modelOptions)
                : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Model Year</span>
            <span className={css.show_label}>
              {publicData.modelYear ? publicData.modelYear : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Odometer</span>
            <span className={css.show_label}>
              {publicData.kilometers ? publicData.kilometers : 'Not Provided'} Km
            </span>
          </li>
          {/* <li>
                <span className={css.form_label}>Engine Size</span>
                <span className={css.show_label}>{publicData.enginesize ? publicData.enginesize : "Not Provided"}</span>
            </li> */}
          {/* <li>
                <span className={css.form_label}>Engine Kilowatts</span>
                <span className={css.show_label}>{publicData.kilowatts ? publicData.kilowatts : "Not Provided"}</span>
            </li> */}
          <li>
            <span className={css.form_label}>Engine Number</span>
            <span className={css.show_label}>
              {publicData.enginenumber ? publicData.enginenumber : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Exterior Colour</span>
            <span className={css.show_label}>
              {publicData.colour ? publicData.colour : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Fuel Type</span>
            <span className={css.show_label}>
              {publicData.fuelType
                ? getSelectedOption(publicData.fuelType, fuelTypeOptions)
                : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Driving Wheels</span>
            <span className={css.show_label}>
              {publicData.drivingWheels
                ? getSelectedOption(publicData.drivingWheels, drivingWheelOptions)
                : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Wheel Size</span>
            <span className={css.show_label}>
              {publicData.wheelSize ? publicData.wheelSize : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Transmission</span>
            <span className={css.show_label}>
              {publicData.transmission
                ? getSelectedOption(publicData.transmission, transmissionOptions)
                : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Body Type</span>
            <span className={css.show_label}>
              {publicData.bodyType
                ? getSelectedOption(publicData.bodyType, bodyTypeOptions)
                : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Interior Type</span>
            <span className={css.show_label}>
              {publicData.interiorType
                ? getSelectedOption(publicData.interiorType, interiorTypeOptions)
                : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Tinted Window</span>
            <span className={css.show_label}>
              {publicData.tintedWindow ? publicData.tintedWindow : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Towbar</span>
            <span className={css.show_label}>
              {publicData.towBar ? publicData.towBar : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Number of Keys</span>
            <span className={css.show_label}>
              {publicData.numberOfKeys ? publicData.numberOfKeys : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Bluetooth</span>
            <span className={css.show_label}>
              {publicData.bluetooth ? publicData.bluetooth : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Heated Seats</span>
            <span className={css.show_label}>
              {publicData.heatedSeats ? publicData.heatedSeats : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Cargo Blind</span>
            <span className={css.show_label}>
              {publicData.cargoBlind ? publicData.cargoBlind : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Bull Bar</span>
            <span className={css.show_label}>
              {publicData.bullBar ? publicData.bullBar : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Extended Warranty</span>
            <span className={css.show_label}>
              {publicData.extendedWarranty ? publicData.extendedWarranty : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Written-Off Vehicle Register</span>
            <span className={css.show_label}>
              {publicData.writtenOffVehicleRegister
                ? publicData.writtenOffVehicleRegister
                : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>PPSR</span>
            <span className={css.show_label}>
              {publicData.ppsr ? publicData.ppsr : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Number of Owners</span>
            <span className={css.show_label}>
              {publicData.numberOfOwners ? publicData.numberOfOwners : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Last Service Date</span>
            <span className={css.show_label}>
              {publicData.lastServiceDate ? publicData.lastServiceDate : 'Not Provided'}
            </span>
          </li>
          <li>
            <span className={css.form_label}>Last Service Odometer</span>
            <span className={css.show_label}>
              {publicData.lastServiceKms ? publicData.lastServiceKms : 'Not Provided'}
            </span>
          </li>
          {/* <li>
            <span className={css.form_label}>Next Service Due</span>
            <span className={css.show_label}>
              {publicData.nextServiceDue
                ? publicData.nextServiceDue
                : 'Not Provided'}
            </span>
          </li> */}
          {/* <li>
            <span className={css.form_label}>Next Service Odometer</span>
            <span className={css.show_label}>
              {publicData.nextServiceKms
                ? publicData.nextServiceKms
                : 'Not Provided'}
            </span>
          </li> */}
          <li>
            <span className={css.form_label}>Dash Warning Lights</span>
            <span className={css.show_label}>
              {publicData.dashWarningLights ? publicData.dashWarningLights : 'Not Provided'}
            </span>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default SectionFeaturesMaybe;

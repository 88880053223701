import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
//  ------- config  ---------------
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
//  ------- utils -------------------
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  REVIEW_TYPE_OF_PROVIDER,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney, convertMoneyToNumber } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureCurrentUser,
  ensureListing,
  ensureOwnListing,
  ensurePaymentMethodCard,
  ensureStripeCustomer,
  ensureTransaction,
  ensureUser,
  getAmount,
  getMetadata,
  getUpdateListingParams,
  isBuyNow,
  isMetadata,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { unitDivisor, convertUnitToSubUnit } from '../../util/currency';
import NotificationsHandlers from '../../util/notifications';
import {
  getUserTxRole,
  TRANSITION_COMPLETE,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_REQUEST_CARD_PAYMENT,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  txIsDeclined,
  txIsDelivered,
  txIsInFirstReviewBy,
  txIsReviewed,
  txLastTransitionData,
  txRoleIsCustomer,
  txRoleIsProvider,
  UPDATE_TRANSACTION,
} from '../../util/transaction';
//  ---------- components & container -------------
import { integrationSdk } from '../../index';
import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  ReviewModal,
  InlineTextButton,
  UserDisplayName,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import SectionImages from './SectionImages';
import SectionConditionsMaybe from './SectionConditionsMaybe';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
// ------------ ducks -----------------------
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  confirmCardPayment,
  initializeCardPaymentData,
  createUpdatePaymentIntentAction,
  capturePaymentIntentAction,
} from '../../ducks/stripe.duck.js';
import {
  sendEnquiry,
  fetchTransactionLineItems,
  setInitialValues,
  requestUpdateListing,
  loadData,
} from './ListingPage.duck';
import { confirmPayment, initiateOrder } from '../CheckoutPage/CheckoutPage.duck';
import { fetchTransaction, sendReview } from '../TransactionPage/TransactionPage.duck';
// ---------- css ------------
import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID, Money } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return {
      formattedPrice,
      priceTitle: formattedPrice,
      formattedInstantPrice: formattedPrice,
      instantPriceTitle: formattedPrice,
    };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
      formattedInstantPrice: `(${price.currency})`,
      instantPriceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

// Convert unformatted value (e.g. 10,00) to Money (or null)
const getPrice = (unformattedValue, currencyConfig) => {
  const isEmptyString = unformattedValue === '';
  try {
    return isEmptyString
      ? null
      : new Money(
          convertUnitToSubUnit(unformattedValue, unitDivisor(currencyConfig.currency)),
          currencyConfig.currency
        );
  } catch (e) {
    return null;
  }
};

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};
export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      biddingData: {},
      valuationData: {},
      buyNowData: {},
      highestBid: null,
      placedBy: null,
      isReviewModalOpen: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBuySubmit = this.handleBuySubmit.bind(this);
    this.handleValuationSubmit = this.handleValuationSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
    this.onCloseEnquiryModal = this.onCloseEnquiryModal.bind(this);
    this.notificationsHandlers = new NotificationsHandlers();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.getListing && props.params) {
      const listingId = new UUID(props.params.id);
      const currentListing = ensureListing(props.getListing(listingId));
      const { metadata } = currentListing.attributes;
      const biddingData =
        metadata && metadata.bidding && typeof metadata.bidding === 'object'
          ? metadata.bidding
          : {};
      const valuationData =
        metadata && metadata.valuation && typeof metadata.valuation === 'object'
          ? metadata.valuation
          : {};
      const buyNowData =
        metadata && metadata.buynow && typeof metadata.buynow === 'object'
          ? Object.values(metadata.buynow)[0]
          : {};
      const highestBidObject =
        Object.values(biddingData).length > 0
          ? Object.values(biddingData).reduce((prev, curr) =>
              Number(prev.bid) > Number(curr.bid) ? prev : curr
            )
          : null;

      const highestBid = highestBidObject ? highestBidObject.bid : null;
      const placedBy = highestBidObject ? highestBidObject.id : null;
      return {
        biddingData,
        valuationData,
        buyNowData,
        highestBid,
        placedBy,
      };
    }
    return null;
  }

  componentDidMount() {
    const { location, onFetchTransaction } = this.props;
    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.txid) {
      const txId = new UUID(params.txid);
      onFetchTransaction(txId, 'customer');
    }
  }
  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  checkDefaultPaymentMethod = () => {
    const { stripeCustomerFetched, currentUser } = this.props;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );
    return !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
  };
  handleSubmit(data, callback) {
    const {
      // history,
      getListing,
      params,
      // callSetInitialValues,
      // onInitializeCardPaymentData,
      stripeCustomerFetched,
      currentUser,
      updateListing,
      onInitiateOrder,
      onConfirmCardPayment,
      onConfirmPayment,
      onFetchTransaction,
      onCreateUpdatePaymentIntentAction,
    } = this.props;
    if (this.checkDefaultPaymentMethod()) {
      const listingId = new UUID(params.id);
      const listing = getListing(listingId);
      const authorId = listing.author ? listing.author.id.uuid : '';

      const { bid, userId, time, buyerStatus, sellerStatus } = data;
      // check bid present with current user
      if (
        isMetadata(listing) &&
        getMetadata(listing)[userId] &&
        getMetadata(listing)[userId].buyer_transaction_id
      ) {
        const existing_buyer_transaction_id = getMetadata(listing)[userId].buyer_transaction_id;
        if (!window.Stripe) {
          throw new Error('Stripe must be loaded for StripePaymentForm');
        }
        if (config.stripe.publishableKey) {
          const txId = new UUID(existing_buyer_transaction_id);

          // step fetch transaction
          const fnFetchTransaction = fnParams => {
            // console.log('fnFetchTransaction', fnParams);
            return onFetchTransaction(fnParams, 'onlytransaction');
          };

          // step update payment intent
          const fnUpdatePaymentIntentFn = fnParams => {
            // console.log('fnUpdatePaymentIntentFn', fnParams);
            let stripePaymentIntentId =
              fnParams?.data?.data?.attributes?.metadata?.paymentIntent?.stripePaymentIntentId;
            return onCreateUpdatePaymentIntentAction({
              payment_method:
                fnParams?.data?.data?.attributes?.metadata?.paymentIntent?.payment_method,
              id: stripePaymentIntentId,
              amount: getAmount(listing),
              capture_method: 'manual',
              confirm: false,
              description: listing?.attributes?.title,
            });
          };

          // // update txn
          // const fnUpdateTransaction = fnParams => {
          //   console.log('fnUpdateTransaction', fnParams);
          //   //  update txn
          //   return integrationSdk.transactions
          //     .updateMetadata(
          //       {
          //         id: new UUID(existing_buyer_transaction_id),
          //         metadata: {
          //           amount: bid,
          //           currency: 'AUD',
          //         },
          //       },
          //       {
          //         expand: true,
          //       }
          //     )
          //   // update txn
          // };

          // step update listing with new bid amount
          const fnUpdateBidNewAmount = fnParams => {
            // console.log('fnUpdateBidNewAmount', fnParams);
            // update listing with new bid amount
            const updateListingParams = getUpdateListingParams({
              type: UPDATE_TRANSACTION,
              listing: { ...listing, bid, time },
              buyerId: userId,
              transaction_id: existing_buyer_transaction_id,
            });
            return updateListing(
              updateListingParams,
              callback,
              authorId,
              TRANSITION_REQUEST_CARD_PAYMENT
            );
            // update listing with new bid amount
          };

          const applyAsync = (acc, val) => acc.then(val);
          const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
          const handlePaymentIntentUpdation = composeAsync(
            fnFetchTransaction,
            fnUpdatePaymentIntentFn,
            // fnUpdateTransaction,
            fnUpdateBidNewAmount
          );
          handlePaymentIntentUpdation(txId)
            .then(res => {
              if (res.status === 200) {
                return callback();
              }
            })
            .catch(err => {
              // console.log('err', err);
              const error = true;
              return callback(error);
            });
        }
      } else {
        // update listing with new bid
        const updateListingParams = {
          metadata: {
            bidding: {
              ...getMetadata(listing),
              [userId]: {
                id: userId,
                timeStamp: time,
                buyerStatus: 0,
                sellerStatus: 0,
                bid,
              },
            },
          },
          id: listing.id,
        };
        updateListing(
          updateListingParams,
          async () => {
            // update done
            const ensuredCurrentUser = ensureCurrentUser(currentUser);
            const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
            const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
              ensuredStripeCustomer.defaultPaymentMethod
            );
            const hasDefaultPaymentMethod = !!(
              stripeCustomerFetched &&
              ensuredStripeCustomer.attributes.stripeCustomerId &&
              ensuredDefaultPaymentMethod.id
            );
            const stripePaymentMethodId = hasDefaultPaymentMethod
              ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
              : null;
            var tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            let order = null;

            // step initiate order
            const fnOnInitiateOrder = fnParams => {
              // console.log('fnOnInitiateOrder', fnParams);
              const transactionId = null;
              const hasAtripePaymentMethod = stripePaymentMethodId;
              if (!hasAtripePaymentMethod) {
                throw new Error(
                  `Missing StripePaymentMethod key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
                );
              }
              return onInitiateOrder(orderParams, transactionId);
            };

            // step create payment intent
            const fnCreateUpdatePaymentIntentAction = fnParams => {
              // console.log('fnCreateUpdatePaymentIntentAction', fnParams);
              order = ensureTransaction(fnParams);
              //update txn id
              const updateListingParams = getUpdateListingParams({
                type: `${TRANSITION_REQUEST_CARD_PAYMENT}-after-initiate`,
                listing: { ...listing, bid, time },
                buyerId: currentUser.id.uuid,
                transaction_id: order.id.uuid,
              });
              updateListing(
                updateListingParams,
                '',
                authorId,
                `${TRANSITION_REQUEST_CARD_PAYMENT}-after-initiate`
              );
              // const hasPaymentIntents =
              //   order.attributes.protectedData && order.attributes.protectedData.stripePaymentIntents;
              // if (!hasPaymentIntents) {
              //   throw new Error(
              //     `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
              //   );
              // }
              // const { stripePaymentIntentClientSecret } = hasPaymentIntents
              //   ? order.attributes.protectedData.stripePaymentIntents.default
              //   : null;
              const paymentParams = { payment_method: stripePaymentMethodId };
              if (!window.Stripe) {
                throw new Error('Stripe must be loaded for StripePaymentForm');
              }
              let params = null;
              if (config.stripe.publishableKey) {
                params = {
                  // stripePaymentIntentClientSecret,
                  orderId: order.id,
                  stripe: window.Stripe(config.stripe.publishableKey),
                  listing,
                  paymentParams,
                  currentUser,
                  type: 'bid',
                  bidData: data,
                  customer: ensuredStripeCustomer?.attributes.stripeCustomerId,
                };
              }
              return onCreateUpdatePaymentIntentAction({
                amount: getAmount(listing),
                payment_method: stripePaymentMethodId,
                customer: ensuredStripeCustomer?.attributes?.stripeCustomerId,
                capture_method: 'manual',
                confirm: false,
                description: listing?.attributes?.title,
              });
            };

            // step update txn metadata
            const fnUpdateTransaction = fnParams => {
              // console.log('fnUpdateTransaction', fnParams);
              //  update txn
              return integrationSdk.transactions.updateMetadata(
                {
                  id: order.id,
                  metadata: {
                    paymentIntent: {
                      status: 'succeeded',
                      payment_method: fnParams.data.payment_method,
                      stripePaymentIntentId: fnParams.data.id,
                      stripePaymentIntentClientSecret: fnParams.data.client_secret,
                    },
                  },
                },
                {
                  expand: true,
                }
              );
              // update txn
            };

            // step update bid status
            const fnUpdateBidToPaidByCustomer = fnParams => {
              // console.log('fnUpdateBidToPaidByCustomer', fnParams);
              // update listing with status 2
              const updateListingParams = getUpdateListingParams({
                type: TRANSITION_CONFIRM_PAYMENT,
                listing: { ...listing, bid, time },
                buyerId: currentUser.id.uuid,
                transaction_id: order.id.uuid,
              });
              return updateListing(updateListingParams, '', authorId, TRANSITION_CONFIRM_PAYMENT);
              // update listing with status 2
            };

            const orderParams = {
              listingId: listing.id.uuid,
              listing,
              currentUser,
              bookingStart: new Date(),
              bookingEnd: tomorrow,
              bookingData: data,
              stripeCustomerFetched,
              paymentMethod: stripePaymentMethodId,
              currentUserId: ensuredStripeCustomer?.attributes.stripeCustomerId,
            };
            const applyAsync = (acc, val) => acc.then(val);
            const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
            const handlePaymentIntentCreation = composeAsync(
              fnOnInitiateOrder,
              fnCreateUpdatePaymentIntentAction,
              fnUpdateTransaction,
              fnUpdateBidToPaidByCustomer
            );
            handlePaymentIntentCreation(orderParams)
              .then(res => {
                if (res.status === 200) {
                  return callback();
                }
              })
              .catch(err => {
                const error = true;
                return callback(error);
              });
            // const onConfirmCardPaymentRes = await onConfirmCardPayment(params);
            // const onConfirmPaymentRes = await onConfirmPayment({
            //   ...onConfirmCardPaymentRes,
            //   ...params,
            // });
            // console.log('onConfirmPaymentRes', onConfirmPaymentRes);
            // if (onConfirmCardPaymentRes) {
            //   // update listing with status 2
            //   const updateListingParams = getUpdateListingParams({
            //     type: TRANSITION_CONFIRM_PAYMENT,
            //     listing: { ...listing, bid, time },
            //     buyerId: currentUser.id.uuid,
            //     transaction_id: order.id.uuid,
            //   });
            //   updateListing(updateListingParams, '', authorId, TRANSITION_CONFIRM_PAYMENT);
            //   return callback();
            // }
          },
          authorId,
          TRANSITION_REQUEST_CARD_PAYMENT
        );
        // update listing with new bid
        // }
      }
    } else {
      const error = true;
      return callback(error);
    }
  }
  redirectToCheckout = () => {
    const {
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
      history,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    var tomorrow = new Date();
    tomorrow.setMinutes(tomorrow.getMinutes() + 2);
    const initialValues = {
      listing,
      bookingData:
        listing.attributes && listing.attributes.metadata && listing.attributes.metadata.buynow,
      bookingDates: {
        bookingStart: new Date(),
        bookingEnd: tomorrow,
      },
      confirmPaymentError: null,
    };
    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bid
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  };
  async handleBuySubmit(data, callback) {
    const {
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
      history,
      currentUser,
      stripeCustomerFetched,
      updateListing,
      onInitiateOrder,
      onConfirmCardPayment,
      onConfirmPayment,
      onLoadData,
      onCapturePaymentIntentAction,
      onCreateUpdatePaymentIntentAction,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    const authorId = listing.author ? listing.author.id.uuid : '';

    const { userId, time, price, buyerStatus, sellerStatus } = data;
    // if buy now object is present check it with current user
    // if it has txn id check transition state if it is completed then mark the listing close and show some relavant msgs
    // if it has no tx id then start from initiate order
    // check listing is vailable for buynow
    const payload = {
      id: params.id,
      userid: authorId,
    };
    const payloadRes = await onLoadData(payload);
    let updatedListingData = null;
    payloadRes.filter(e => {
      if (e && e.data && e.data.data && e.data.data.type === 'listing') {
        updatedListingData = e.data.data;
      }
    });
    if (updatedListingData) {
      const metadata =
        updatedListingData &&
        updatedListingData.attributes &&
        updatedListingData.attributes.metadata;
      if (isMetadata(listing) && isBuyNow(metadata) && getMetadata(updatedListingData)) {
        if (getMetadata(updatedListingData)[currentUser.id.uuid]) {
          if (getMetadata(updatedListingData)[currentUser.id.uuid].buyer_transaction_id) {
            // console.log('Transaction id is present inform the user about the issue');
          } else {
            // console.log('Transaction id is not present. Start buying again');
          }
        } else {
          // console.log('Someonelese is trying to buy it');
          const error = true;
          callback(error);
        }
      } else {
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        let order = null;
        let paymentIntent = null;
        // step initiate order
        const ensuredCurrentUser = ensureCurrentUser(currentUser);
        const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
        const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
          ensuredStripeCustomer.defaultPaymentMethod
        );
        const hasDefaultPaymentMethod = !!(
          stripeCustomerFetched &&
          ensuredStripeCustomer.attributes.stripeCustomerId &&
          ensuredDefaultPaymentMethod.id
        );
        const stripePaymentMethodId = hasDefaultPaymentMethod
          ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
          : null;

        // step update listing with buynow object
        const fnUpdateListingBuyNowInitial = fnParams => {
          // console.log('fnUpdateBidToPaidByCustomer', fnParams);
          // update listing with status 1
          const updateListingParams = {
            metadata: {
              buynow: {
                [userId]: {
                  id: userId,
                  timeStamp: time,
                  buyerStatus,
                  sellerStatus,
                  price: updatedListingData?.attributes?.publicData?.instantPrice / 100,
                },
              },
            },
            id: updatedListingData && updatedListingData.id,
          };
          return updateListing(updateListingParams, '', authorId, TRANSITION_CONFIRM_PAYMENT);
          // update listing with status 2
        };

        // step initiate order
        const fnOnInitiateOrder = fnParams => {
          // console.log('fnOnInitiateOrder', fnParams);
          updatedListingData = fnParams.data.data;
          const orderParams = {
            listingId: updatedListingData.id.uuid,
            listing: updatedListingData,
            currentUser,
            bookingStart: new Date(),
            bookingEnd: tomorrow,
            bookingData: data,
            stripeCustomerFetched,
            type: 'buynow',
          };
          const transactionId = null;
          const hasAtripePaymentMethod = stripePaymentMethodId;
          if (!hasAtripePaymentMethod) {
            throw new Error(
              `Missing StripePaymentMethod key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
            );
          }
          return onInitiateOrder(orderParams, transactionId);
        };

        // step create payment intent
        const fnCreateUpdatePaymentIntentAction = fnParams => {
          // console.log('fnCreateUpdatePaymentIntentAction', fnParams);
          order = ensureTransaction(fnParams);
          // const hasPaymentIntents =
          //   order.attributes.protectedData && order.attributes.protectedData.stripePaymentIntents;
          // if (!hasPaymentIntents) {
          //   throw new Error(
          //     `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
          //   );
          // }
          // const { stripePaymentIntentClientSecret } = hasPaymentIntents
          //   ? order.attributes.protectedData.stripePaymentIntents.default
          //   : null;
          // const paymentParams = { payment_method: stripePaymentMethodId };
          // if (!window.Stripe) {
          //   throw new Error('Stripe must be loaded for StripePaymentForm');
          // }
          // let params = null;
          // if (config.stripe.publishableKey) {
          //   params = {
          //     // stripePaymentIntentClientSecret,
          //     orderId: order.id,
          //     stripe: window.Stripe(config.stripe.publishableKey),
          //     listing,
          //     paymentParams,
          //     currentUser,
          //     type: 'buynow',
          //     bidData: data,
          //     customer: ensuredStripeCustomer?.attributes.stripeCustomerId,
          //   };
          // }
          return onCreateUpdatePaymentIntentAction({
            amount: getAmount(updatedListingData),
            payment_method: stripePaymentMethodId,
            customer: ensuredStripeCustomer?.attributes?.stripeCustomerId,
            capture_method: 'automatic',
            confirm: true,
            description: updatedListingData?.attributes?.title,
          });
        };

        const fnOnConfirmPayment = fnParams => {
          // console.log('fnOnConfirmPayment', fnParams);
          paymentIntent = fnParams;
          const hasPaymentIntents = paymentIntent.status === 200;
          if (!hasPaymentIntents) {
            throw new Error(
              `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
            );
          }
          return onConfirmPayment({
            listing: updatedListingData,
            type: 'buynow',
            transactionId: order.id,
            payment_method: order?.attributes?.metadata?.paymentIntent?.payment_method,
          });
        };

        // step update bid status
        const fnUpdateListingBuyNowByCustomer = fnParams => {
          // console.log("fnUpdateListingBuyNowByCustomer",fnParams)
          // console.log('fnUpdateBidToPaidByCustomer', fnParams);
          // update listing with status 3
          const updateListingParams = {
            metadata: {
              buynow: {
                [userId]: {
                  id: userId,
                  timeStamp: +new Date(),
                  buyerStatus: 3,
                  sellerStatus: 3,
                  price: updatedListingData?.attributes?.publicData?.instantPrice / 100,
                  buyer_transaction_id: order?.id?.uuid,
                },
              },
            },
            id: updatedListingData && updatedListingData.id,
          };
          return updateListing(updateListingParams, '', authorId, TRANSITION_CONFIRM_PAYMENT);
          // update listing with status 2
        };

        const fnCloseListing = fnParams => {
          // console.log('fnCloseListing', fnParams);
          return integrationSdk.listings.close(
            {
              id: listing.id.uuid,
            },
            {
              expand: true,
            }
          );
        };

        const applyAsync = (acc, val) => acc.then(val);
        const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
        const handlePaymentIntentUpdation = composeAsync(
          fnUpdateListingBuyNowInitial,
          fnOnInitiateOrder,
          fnCreateUpdatePaymentIntentAction,
          fnOnConfirmPayment,
          fnUpdateListingBuyNowByCustomer,
          fnCloseListing
        );
        handlePaymentIntentUpdation()
          .then(res => {
            if (res.status === 200) {
              onLoadData(payload);
              return callback();
            }
          })
          .catch(err => {
            console.log('err', err);
            const error = true;
            return callback(error);
          });
      }
    } else {
      const error = true;
      callback(error);
      // throw new Error('Data not loaded');
    }
  }

  async handleValuationSubmit(data, callback) {
    const { getListing, params } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);
    const authorId = listing.author ? listing.author.id.uuid : '';

    const { valuation, userId, time } = data;

    await this.props.updateListing(
      {
        metadata: {
          valuation: {
            ...this.state.valuationData,
            [userId]: {
              valuation,
              id: userId,
              timeStamp: time,
            },
          },
        },
        id: listing.id,
      },
      callback,
      authorId
    );
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = {
        from: `${location.pathname}${location.search}${location.hash}`,
      };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, {
        enquiryModalOpenForListingId: params.id,
      });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onCloseEnquiryModal = () => {
    this.setState({ enquiryModalOpen: false });
  };

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }
  hasUserLeftAReviewFirst = (userRole, transaction) => {
    // Because function txIsInFirstReviewBy uses isCustomer to check in which state the reviews are
    // we should also use isCustomer insted of isProvider
    const isCustomer = txRoleIsCustomer(userRole);
    return txIsInFirstReviewBy(transaction, isCustomer);
  };
  resolveTransitionMessage = () => {
    const { transaction, intl, currentUser } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const customer = currentTransaction.customer;
    const provider = currentTransaction.provider;
    if (currentTransaction && customer && provider && currentUser) {
      const lastTransition = currentTransaction?.attributes?.lastTransition;
      const ownRole = getUserTxRole(currentUser.id, currentTransaction);
      const lastTransitionBy = txLastTransitionData(transaction);
      const isOwnTransition = ownRole === lastTransitionBy.by;
      const otherUsersName = txRoleIsProvider(ownRole) ? (
        <UserDisplayName user={customer} intl={intl} />
      ) : (
        <UserDisplayName user={provider} intl={intl} />
      );
      const displayName = otherUsersName;
      switch (lastTransition) {
        case TRANSITION_COMPLETE:
          // Show the leave a review link if the state is delivered and if the current user is the first to leave a review
          const reviewPeriodJustStarted = txIsDelivered(transaction);
          const reviewAsFirstLink = reviewPeriodJustStarted ? (
            <InlineTextButton onClick={() => this.onOpenReviewModal()}>
              <FormattedMessage id="ActivityFeed.leaveAReview" values={{ displayName }} />
            </InlineTextButton>
          ) : null;

          return (
            <FormattedMessage
              id="ActivityFeed.transitionComplete"
              values={{ reviewLink: reviewAsFirstLink }}
            />
          );

        case TRANSITION_REVIEW_1_BY_PROVIDER:
        case TRANSITION_REVIEW_1_BY_CUSTOMER:
          if (isOwnTransition) {
            return (
              <FormattedMessage id="ActivityFeed.ownTransitionReview" values={{ displayName }} />
            );
          } else {
            // show the leave a review link if current user is not the first
            // one to leave a review
            const reviewPeriodIsOver = txIsReviewed(transaction);
            const userHasLeftAReview = this.hasUserLeftAReviewFirst(ownRole, transaction);
            const reviewAsSecondLink = !(reviewPeriodIsOver || userHasLeftAReview) ? (
              <InlineTextButton onClick={() => this.onOpenReviewModal()}>
                <FormattedMessage id="ActivityFeed.leaveAReviewSecond" values={{ displayName }} />
              </InlineTextButton>
            ) : null;
            return (
              <FormattedMessage
                id="ActivityFeed.transitionReview"
                values={{ displayName, reviewLink: reviewAsSecondLink }}
              />
            );
          }
        case TRANSITION_REVIEW_2_BY_PROVIDER:
        case TRANSITION_REVIEW_2_BY_CUSTOMER:
          if (isOwnTransition) {
            return (
              <FormattedMessage id="ActivityFeed.ownTransitionReview" values={{ displayName }} />
            );
          } else {
            return (
              <FormattedMessage
                id="ActivityFeed.transitionReview"
                values={{ displayName, reviewLink: null }}
              />
            );
          }
        case TRANSITION_EXPIRE_REVIEW_PERIOD:
          if (isOwnTransition) {
            return <FormattedMessage id="ActivityFeed.ownTransitionExpiredReview" />;
          } else {
            return <FormattedMessage id="ActivityFeed.transitionExpiredReview" />;
          }
        default:
          // log.error(new Error('Unknown transaction transition type'), 'unknown-transition-type', {
          //   transitionType: currentTransition,
          // });
          return '';
      }
    }
  };
  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      history,
      transaction,
      stripeAccountFetched,
      stripeAccount,
      onLoadData,
    } = this.props;
    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, userid: '', ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';
    const lmctUnverified =
      !!currentUser &&
      !!currentUser.id &&
      !!currentUser.attributes.profile &&
      !!currentUser.attributes.profile.publicData &&
      !currentUser.attributes.profile.publicData.lmctVerified;
    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;
    const emailUnverified =
      !!(currentUser && currentUser.id) && !currentUser.attributes.emailVerified;

    // stripe verified
    const stripeConnected = currentUser && currentUser.id && currentUser.attributes.stripeConnected;
    const getStripeAccountData = stripeAccount =>
      (stripeAccount && stripeAccount.attributes && stripeAccount.attributes.stripeAccountData) ||
      null;

    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;
    const hasRequirements = (stripeAccountData, requirementType) =>
      stripeAccountData != null &&
      stripeAccountData.requirements &&
      Array.isArray(stripeAccountData.requirements[requirementType]) &&
      stripeAccountData.requirements[requirementType].length > 0;

    const requirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
        hasRequirements(stripeAccountData, 'currently_due'));

    const savedCountry = stripeAccountData ? stripeAccountData.country : null;
    const showVerificationNeeded = stripeConnected && requirementsMissing;
    // stripe verified
    // txn declined
    const current_user_id = currentUser && currentUser.id && currentUser.id.uuid;
    let exisitingTxDeclined = false;
    if (
      currentListing &&
      currentListing.attributes &&
      currentListing.attributes.metadata &&
      currentListing.attributes.metadata.bidding
    ) {
      let txn_id =
        currentListing.attributes.metadata.bidding[current_user_id] &&
        currentListing.attributes.metadata.bidding[current_user_id].buyer_transaction_id;
      if (txn_id === (transaction && transaction.id && transaction.id.uuid)) {
        exisitingTxDeclined = txIsDeclined(transaction);
      }
    }
    // txn dexlined
    const stripeAccountMissing =
      !!(currentUser && currentUser.id) && !currentUser.attributes.stripeConnected;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const listingTypeMain = publicData.listingType ? publicData.listingType : null;

    const bookingTitle = listingTypeMain ? (
      listingTypeMain === 'bid' ? (
        <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
      ) : (
        <FormattedMessage id="ListingPage.valuationTitle" values={{ title: richTitle }} />
      )
    ) : null;

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const hasInstantPrice = currentListing?.attributes?.publicData?.instantPrice;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));
    const transactionRole =
      currentUser && currentListing && currentUser.id.uuid === currentListing.author.id.uuid
        ? 'provider'
        : 'customer';
    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');
    const authorId = ensuredAuthor && ensuredAuthor.id ? ensuredAuthor.id.uuid : '';

    const priceValue = price ? convertMoneyToNumber(price) : null;
    let { instantPrice } = publicData;
    instantPrice = instantPrice ? getPrice(instantPrice / 100, config.currencyConfig) : '';
    const { formattedInstantPrice, instantPriceTitle } = instantPrice
      ? priceData(instantPrice, intl)
      : { formattedInstantPrice: null, instantPriceTitle: null };
    const buyNowTitle = listingTypeMain ? (
      listingTypeMain === 'bid' ? (
        <FormattedMessage
          id="ListingPage.buyTitle"
          values={{ title: richTitle, price: formattedInstantPrice ?? '' }}
        />
      ) : null
    ) : null;
    const { formattedPrice, priceTitle } = price
      ? priceData(price, intl)
      : { formattedPrice: null, priceTitle: null };

    const handleBookingSubmit = (value, callback, type) => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        if (type === 'bid') {
          this.handleSubmit(value, callback);
        } else if (type === 'valuation') {
          this.handleValuationSubmit(value, callback);
        } else if (type === 'buyNow') {
          this.handleBuySubmit(value, callback);
        }
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: price ? formattedPrice : 'Valuate', siteTitle }
    );

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );
    const onSubmitReview = values => {
      const { onSendReview, transaction, currentUser } = this.props;
      const listingId = new UUID(this.props.params.id);
      const currentListing = ensureListing(this.props.getListing(listingId));
      const transactionRole =
        currentUser.id.uuid === currentListing.author.id.uuid ? 'provider' : 'customer';
      const currentTransaction = ensureTransaction(transaction);
      let { reviewRating, reviewContent } = values;
      if (Array.isArray(reviewContent)) {
        reviewContent = reviewContent[0];
      }
      const rating = Number.parseInt(reviewRating, 10);
      const listingTitle =
        currentTransaction && currentTransaction.listing && currentTransaction.listing.attributes
          ? currentTransaction.listing.attributes.title
          : '';
      const customerId =
        currentTransaction && currentTransaction.customer && currentTransaction.customer.id
          ? currentTransaction.customer.id.uuid
          : '';
      const providerId =
        currentTransaction && currentTransaction.provider && currentTransaction.provider.id
          ? currentTransaction.provider.id.uuid
          : '';

      onSendReview(transactionRole, currentTransaction, rating, reviewContent)
        .then(r => {
          if (transactionRole === 'customer' && providerId) {
            this.notificationsHandlers.setNotification(providerId, {
              title: 'Review Added',
              type: 'reviewAdded',
              userId: providerId,
              message: `A review has been added by a customer for ${listingTitle ?? ''}`,
            });
          } else if (transactionRole === 'provider' && customerId) {
            this.notificationsHandlers.setNotification(customerId, {
              title: 'Review Added',
              type: 'reviewAdded',
              userId: customerId,
              message: `A review has been added by a seller for ${listingTitle ?? ''}`,
            });
          }
          this.setState({ isReviewModalOpen: false, reviewSubmitted: true });
        })
        .catch(e => {
          // Do nothing.
        });
    };
    const transmissionOptions = findOptionsForSelectFilter('transmission', filterConfig);
    const bodyTypeOptions = findOptionsForSelectFilter('body-type', filterConfig);
    const makeOptions = findOptionsForSelectFilter('make', filterConfig);
    let modelOptions = findOptionsForSelectFilter('model', config.custom.filters);
    const fuelTypeOptions = findOptionsForSelectFilter('fuelType', filterConfig);
    const drivingWheelOptions = findOptionsForSelectFilter('drivingWheels', filterConfig);
    const interiorTypeOptions = findOptionsForSelectFilter('interiorType', filterConfig);

    const make = (publicData && publicData.make) ?? '';
    modelOptions = modelOptions[make];

    const reviewsOfProvider = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_PROVIDER);
    const currentTransaction = ensureTransaction(this.props.transaction);
    // const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const otherUserDisplayName = userDisplayNameAsString(currentCustomer, '');

    // const {
    //   customerDisplayName,
    //   otherUserDisplayName,
    //   otherUserDisplayNameString,
    // } = displayNames(currentUser, currentProvider, currentCustomer, intl);
    const transitionMessage = this.resolveTransitionMessage();
    // const currentTransition = lastTransition;

    // const deletedReviewContent = intl.formatMessage({ id: 'ActivityFeed.deletedReviewContent' });
    // let reviewComponent = null;

    // if (transitionIsReviewed(lastTransition)) {
    //   if (isCustomerReview(currentTransition)) {
    //     const review = reviewByAuthorId(currentTransaction, customer.id);
    //     reviewComponent = review ? (
    //       <Review content={review.attributes.content} rating={review.attributes.rating} />
    //     ) : (
    //       <Review content={deletedReviewContent} />
    //     );
    //   } else if (isProviderReview(currentTransition)) {
    //     const review = reviewByAuthorId(currentTransaction, provider.id);
    //     reviewComponent = review ? (
    //       <Review content={review.attributes.content} rating={review.attributes.rating} />
    //     ) : (
    //       <Review content={deletedReviewContent} />
    //     );
    //   }
    // }
    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              <SectionImages
                title={title}
                listing={currentListing}
                isOwnListing={isOwnListing}
                history={history}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingType,
                  tab: listingTab,
                }}
                imageCarouselOpen={this.state.imageCarouselOpen}
                onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                handleViewPhotosClick={handleViewPhotosClick}
                onManageDisableScrolling={onManageDisableScrolling}
                showCarousel={true}
                backTo="LandingPage"
              />
              <div className={css.contentContainer}>
                {/* <SectionAvatar user={currentAuthor} params={params} /> */}
                <div className={css.mainContent}>
                  <SectionHeading
                    priceTitle={priceTitle}
                    formattedPrice={formattedPrice}
                    richTitle={richTitle}
                    lmctUnverified={lmctUnverified}
                    publicData={publicData}
                    hostLink={hostLink}
                    showContactUser={showContactUser}
                    isAuthenticated={isAuthenticated}
                    onContactUser={this.onContactUser}
                  />
                  <SectionDescriptionMaybe description={description} />
                  <SectionFeaturesMaybe
                    transmissionOptions={transmissionOptions}
                    bodyTypeOptions={bodyTypeOptions}
                    makeOptions={makeOptions}
                    publicData={publicData}
                    modelOptions={modelOptions}
                    fuelTypeOptions={fuelTypeOptions}
                    drivingWheelOptions={drivingWheelOptions}
                    interiorTypeOptions={interiorTypeOptions}
                  />
                  <SectionConditionsMaybe
                    title={title}
                    publicData={publicData}
                    listing={currentListing}
                    isOwnListing={isOwnListing}
                  />
                  {this.props.transaction
                    ? transitionMessage
                    : // <InlineTextButton
                      //     onClick={() => {
                      //       this.setState({ isReviewModalOpen: true });
                      //     }}
                      //   >
                      //     <FormattedMessage
                      //       id="ActivityFeed.leaveAReview"
                      //       values={{ displayName: authorDisplayName }}
                      //     />
                      //   </InlineTextButton>
                      null}
                  <SectionReviews
                    reviews={reviewsOfProvider}
                    fetchReviewsError={fetchReviewsError}
                  />
                  <ReviewModal
                    id="ReviewOrderModal"
                    isOpen={this.state.isReviewModalOpen}
                    onCloseModal={() => {
                      this.setState({ isReviewModalOpen: false });
                    }}
                    onManageDisableScrolling={onManageDisableScrolling}
                    onSubmitReview={onSubmitReview}
                    transactionRole={transactionRole}
                    revieweeName={otherUserDisplayName}
                    // reviewSent={this.state.reviewSubmitted}
                    // sendReviewInProgress={sendReviewInProgress}
                    // sendReviewError={sendReviewError}
                  />
                </div>
                <BookingPanel
                  hasDefaultPaymentMethod={this.checkDefaultPaymentMethod()}
                  emailUnverified={emailUnverified}
                  showVerificationNeeded={showVerificationNeeded}
                  exisitingTxDeclined={exisitingTxDeclined}
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleBookingSubmit}
                  redirectToCheckout={this.redirectToCheckout}
                  hostingTitle={title}
                  title={bookingTitle}
                  buyNowTitle={buyNowTitle}
                  authorDisplayName={authorDisplayName}
                  authorId={authorId}
                  onManageDisableScrolling={onManageDisableScrolling}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onContactUser={this.onContactUser}
                  isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                  onCloseEnquiryModal={this.onCloseEnquiryModal}
                  sendEnquiryError={sendEnquiryError}
                  sendEnquiryInProgress={sendEnquiryInProgress}
                  onSubmitEnquiry={this.onSubmitEnquiry}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  currentUser={currentUser}
                  isAuthenticated={isAuthenticated}
                  placedBy={this.state.placedBy}
                  highestBid={this.state.highestBid}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  geolocation={geolocation}
                  publicData={publicData}
                  buyNowData={this.state.buyNowData}
                  listingTypeMain={listingTypeMain}
                  priceValue={priceValue}
                  instantPrice={instantPrice}
                  formattedInstantPrice={formattedInstantPrice}
                  listingId={currentListing.id}
                  transaction={transaction}
                  stripeAccountMissing={stripeAccountMissing}
                  onLoadData={() =>
                    onLoadData({
                      id: params.id,
                      userid: authorId,
                    })
                  }
                  hasInstantPrice={hasInstantPrice}
                />
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  updateListing: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  onInitiateOrder: func.isRequired,
  onConfirmCardPayment: func.isRequired,
  onConfirmPayment: func.isRequired,
  onLoadData: func.isRequired,
  onSendReview: func.isRequired,
};

const mapStateToProps = state => {
  const { stripeCustomerFetched } = state.CheckoutPage;
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
  } = state.ListingPage;
  const { stripeAccountFetched, stripeAccount } = state.stripeConnectAccount;
  const { currentUser } = state.user;
  const { transactionRef } = state.TransactionPage;
  const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
  const transaction = transactions.length > 0 ? transactions[0] : null;
  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };
  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    transaction,
    stripeAccountFetched,
    stripeAccount,
    stripeCustomerFetched,
  };
};

const mapDispatchToProps = dispatch => ({
  updateListing: (values, callback, authorId = '', transitionType, transaction) =>
    dispatch(requestUpdateListing(values, callback, authorId, transitionType, transaction)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onInitiateOrder: (params, transactionId) => dispatch(initiateOrder(params, transactionId)),
  onConfirmCardPayment: params => dispatch(confirmCardPayment(params)),
  onConfirmPayment: params => dispatch(confirmPayment(params)),
  onLoadData: params => dispatch(loadData(params)),
  onSendReview: (role, tx, reviewRating, reviewContent) =>
    dispatch(sendReview(role, tx, reviewRating, reviewContent)),
  onFetchTransaction: (txId, txRole) => dispatch(fetchTransaction(txId, txRole)),
  onCreateUpdatePaymentIntentAction: params => dispatch(createUpdatePaymentIntentAction(params)),
  onCapturePaymentIntentAction: params => dispatch(capturePaymentIntentAction(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
